import React from "react";
import { BsCart3 } from "react-icons/bs";
import { RiBankCardLine } from "react-icons/ri";

const payment = () => {
  return (
    <section className="w-full h-72 bg-gray-700 flex gap-6 flex-col mt-9 justify-center items-center lg:flex-row lg:gap-60">
      <div className="flex flex-col gap-1 justify-center items-center text-center text-white">
        <BsCart3 className="text-yellow-600" />
        <div className="flex flex-col">
          <span className="text-xs lg:text-lg">Pagamento a Dinheiro</span>
        </div>
      </div>
      <div className="flex flex-col gap-1 justify-center items-center  text-center text-white">
        <RiBankCardLine className="text-yellow-600" />
        <p className="text-xs lg:text-lg">Pagamento Multibanco</p>
        <span className="text-xs lg:text-lg">
          Pagamentos Nacional/Estrangeiro
        </span>
        <img
          src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,q_59,w_251/v1637672578/Fotos%20do%20FrontEnd/mbpagamento_ynqi5x.png"
          alt="Metodos de Pagamento a Multibanco "
          className="w-40 lg:w-64"
        />
      </div>
      <div className="flex flex-col justify-center items-center w-24 text-center lg:w-40">
        <a href="https://www.livroreclamacoes.pt/Pedido/Reclamacao">
          <img
            src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,q_46,w_185/v1637672579/Fotos%20do%20FrontEnd/reclamacoes_juieyv.png"
            alt="Livro de Reclamações Online"
          />
        </a>
      </div>
    </section>
  );
};

export default payment;
