import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTripadvisor,
} from "react-icons/fa";

const variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 0.7, ease: "easeInOut" },
  },
};
const variantsimg = {
  hidden: {
    x: "200vh",
    opacity: 0,
  },

  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.9,
      ease: "easeInOut",
      type: "spring",
      stiffness: 70,
    },
  },
};

const Hero = () => {
  return (
    <header className="relative flex justify-center items-center w-full hScreen hScreen2">
      <img
        src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,q_53,w_600/v1637672578/Fotos%20do%20FrontEnd/hero_qcavl9.jpg"
        alt="Mesa Principal"
        className=" absolute z-10  w-full hScreen object-cover  bg-center bg-no-repeat opacity-50  lg:hidden"
      />

      <motion.img
        variants={variantsimg}
        initial="hidden"
        animate="show"
        src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,q_53,w_600/v1637672578/Fotos%20do%20FrontEnd/hero_qcavl9.jpg"
        alt="Mesa Principal"
        className=" hidden   lg:block  lg:absolute image-large lg:w-3/12 lg:opacity-100 lg:h-full lg:top-10 lg:shadow-xl"
      />
      <div className="image-box__overlay hScreen  lg:hidden  "></div>
      <motion.section
        className=" relative w-4/5 mb-7 z-30 lg:mt-7 "
        variants={variants}
        initial="hidden"
        animate="show"
      >
        <div className="flex mb-2  ">
          <a href="https://www.facebook.com/restaurantemontealegre1">
            <FaFacebookSquare className="text-white text-lg lg:text-2xl mr-3 shadow-md cursor-pointer hover:text-yellow-500 lg:text-red-700 lg:shadow-none " />
          </a>
          <a href="https://www.instagram.com/restmontealegreehotmail.com_/">
            <FaInstagramSquare className="text-white text-lg lg:text-2xl mr-3 shadow-md cursor-pointer hover:text-yellow-500 lg:text-red-700 lg:shadow-none " />
          </a>
          <a href="https://www.tripadvisor.pt/Restaurant_Review-g189174-d12278383-Reviews-Restaurante_Monte_Alegre-Guimaraes_Braga_District_Northern_Portugal.html">
            <FaTripadvisor className="text-white text-lg lg:text-2xl mr-3 shadow-md cursor-pointer hover:text-yellow-500 lg:text-red-700 lg:shadow-none " />
          </a>
        </div>
        <div>
          <h1 className=" mb-2 text-yellow-500 text-3xl  md:text-4xl font-bold tracking-wide md:w-8/12 lg:w-7/12 lg:text-5xl">
            Restaurante Monte Alegre
          </h1>
          <p className=" mb-3 text-white text-base md:text-lg font-light lg:font-normal tracking-wide leading-tight md:w-9/12 lg:text-gray-600 lg:w-7/12">
            Cozinhar é como um espetáculo: É preciso muita organização, treino,
            dedicação, conhecimento e amor para que, no final, os cinco sentidos
            o aplaudam de pé. Comida Tradicional e Regional de Excelência!
          </p>
          <div className="pb-10">
            <Link to="/menu">
              <button
                type="button"
                className="text-sm font-medium shadow-md h-8 px-2  text-gray-700 transition-colors duration-150 bg-yellow-500 rounded-sm focus:shadow-outline hover:bg-yellow-600 md:px-5 md:h-8"
              >
                Quero Comer...
              </button>
            </Link>
            <a href="https://goo.gl/maps/CzF7fPEydbsQdJXF9">
              <button
                type="button"
                className="text-sm font-medium shadow-md h-8 px-2 ml-2  text-indigo-100 transition-colors duration-150 bg-red-800 rounded-sm focus:shadow-outline hover:bg-red-900 md:px-5 md:h-8 md:text-base"
              >
                Encontre-nos
              </button>
            </a>
          </div>
        </div>
      </motion.section>
    </header>
  );
};

export default Hero;
