import React from "react";
import { RiStarSFill } from "react-icons/ri";
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import { FaTripadvisor } from "react-icons/fa";

const destaque = () => {
  return (
    <section className="w-3/2 h-full bg-gray-100">
      <div className="hidden lg:flex lg:w-7/12 lg:items-center lg:justify-between lg:pt-2">
        <a href="tel:351253098320">
          <span className="hidden text-red-700 text-small mb-1 font-medium lg:block lg:ml-28 lg:text-base">
            +351 253 098 320{" "}
            <span className="text-xs pl-2">*Rede Fixa Nacional</span>
          </span>
        </a>
        <div className="text-yellow-600 flex gap-2 text-md relative left-16 lg:text-xl">
          <a href="https://www.facebook.com/restaurantemontealegre1">
            <AiOutlineFacebook className="text-md" />
          </a>
          <a href="https://www.instagram.com/restmontealegreehotmail.com_/">
            <AiOutlineInstagram className="text-md" />
          </a>
          <a href="https://www.tripadvisor.pt/Restaurant_Review-g189174-d12278383-Reviews-Restaurante_Monte_Alegre-Guimaraes_Braga_District_Northern_Portugal.html">
            <FaTripadvisor className="text-md" />
          </a>
        </div>
      </div>
      <div className="flex flex-col pt-10  lg:flex-row lg:justify-center lg:items-center ">
        <img
          className=" w-full h-full order-2  mx-auto lg:w-4/12 lg:order-1 lg:mx-0 lg:relative lg:top-32 lg:right-20 "
          src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,w_678/v1637672579/Fotos%20do%20FrontEnd/prato-destaque_gdfit4.png"
          alt="Prato Medalão de Novilho com Crosta de Alheira e Bora"
        />
        <div className="flex flex-col lg:order-2 lg:w-4/12">
          <div className="pt-5 pb-1 relative w-1/2 left-star text-yellow-500 flex flex-row text-xl  lg:pt-14 lg:text-2xl">
            <RiStarSFill />
            <RiStarSFill />
            <RiStarSFill />
            <RiStarSFill />
            <RiStarSFill />
          </div>
          <div className="order-1 w-4/5 mx-auto  ">
            <h2 className="mb-2 text-yellow-700 text-2xl font-medium lg:text-3xl">
              Medalhão De Novilho Com Crosta De Alheira e Broa
            </h2>
            <p className=" mb-2 text-sm text-gray-500 font-medium leading-4 lg:text-xl">
              Prato vencedor do concurso "O melhor sabor de Portugal - 2018"
            </p>
          </div>
        </div>
      </div>

      <div className="bubble flex flex-col left-1/4 top-1 bg-gray-300 lg:left-2/4 lg:top-5 lg:bg-yellow-500">
        <div className=" pb-1  text-yellow-500 flex flex-row text-xs lg:text-2xl lg:text-red-700">
          <RiStarSFill />
          <RiStarSFill />
          <RiStarSFill />
          <RiStarSFill />
          <RiStarSFill />
        </div>
        <p className="text-xs font-normal leading-4 mt-1 lg:text-lg lg:font-normal">
          Fantástico Medalhão, o melhor que já comi. Para repetir novamente! 😋
        </p>
        <div className="flex items-center justify-end">
          <span className="text-xs font-semibold text-red-700 pt-1 pr-2 lg:text-base lg:font-normal">
            Joana M.
          </span>
          <figure>
            <img
              className="w-5 h-5 rounded-full opacity-90 shadow-sm  lg:w-10 lg:h-10"
              src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,q_52,w_182/v1637672578/Fotos%20do%20FrontEnd/faceui-1_lhmdvc.jpg"
              alt="Imagem do comentario"
            />
          </figure>
        </div>
      </div>

      <div className="bubble-two flex flex-col top-7 left-1/3 bg-gray-300 lg:left-2/3 lg:top-10">
        <div className=" pb-1  text-yellow-500 flex flex-row text-xs lg:text-2xl ">
          <RiStarSFill />
          <RiStarSFill />
          <RiStarSFill />
          <RiStarSFill />
          <RiStarSFill />
        </div>
        <p className="text-xs font-normal leading-4 mt-1 lg:text-lg lg:font-normal">
          Este Medalão de Novilho é absolutamente divinal! Empratamento
          perfeito, como sempre. 👍
        </p>
        <div className="flex items-center justify-end">
          <span className="text-xs font-semibold text-red-700 pr-2 pt-3 lg:text-base lg:font-normal">
            João D. Antunes
          </span>
          <figure>
            <img
              className="w-5 h-5 rounded-full opacity-90 shadow-sm mt-3 lg:w-10 lg:h-10"
              src="https://res.cloudinary.com/dobzqmm6v/image/upload/c_scale,q_46,w_293/v1637672578/Fotos%20do%20FrontEnd/faceui-2_ctb3ay.jpg"
              alt="Imagem do comentario"
              id="chefe"
            />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default destaque;
