import React from "react"
import { Link } from "gatsby"
import { GiForkKnifeSpoon } from "react-icons/gi"

const menu = () => {
  return (
    <section className="flex flex-col w-4/5 h-screen mx-auto gap-2 mt-7  menu-img-height lg:mb-16">
      <div className="pb-5">
        <h3 className="text-xs font-bold text-red-700 flex justify-items-start items-center lg:text-lg">
          <GiForkKnifeSpoon className="text-black mr-1" />
          Menu
        </h3>
      </div>
      <div className="flex flex-col w-full h-full gap-4 lg:flex-row ">
        <div className="w-full h-1/3 bg-gray-500 flex flex-col justify-center items-center bg-center bg-cover carnes-img lg:w-2/6 lg:h-full transform hover:scale-105 transition-all ease-in-out shadow-xl">
          <Link to="/carnes">
            <button
              type="button"
              className="p-5 text-2xl font-semibold text-white  bg-opacity-50 border-2"
            >
              Carnes
            </button>
          </Link>
        </div>
        <div className="w-full h-1/3 bg-red-400 flex flex-col justify-center items-center bg-center bg-cover peixe-img lg:w-2/6 lg:h-full transform hover:scale-105 transition-all ease-in-out shadow-xl">
          <Link to="/peixe">
            <button
              type="button"
              className="p-5 text-2xl font-semibold text-white  bg-opacity-50 border-2"
            >
              Peixe
            </button>
          </Link>
        </div>
        <div className="w-full h-1/3 bg-green-400 flex flex-col justify-center items-center bg-center bg-cover sobremesas-img lg:w-2/6 lg:h-full transform hover:scale-105 transition-all ease-in-out shadow-xl">
          <Link to="/sobremesas">
            <button
              type="button"
              className="p-5 text-2xl font-semibold text-white  bg-opacity-50 border-2"
            >
              Sobremesas
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default menu
