import React from "react";

const hero2 = () => {
  return (
    <section className="flex items-center justify-center h-screen mx-auto  bg-fixed bg-center bg-cover custom-img ">
      <a href="https://goo.gl/maps/CzF7fPEydbsQdJXF9">
        <div className="p-5 text-2xl font-semibold text-white  bg-opacity-50 border-2">
          Gostarias de nos visitar?
        </div>
      </a>
      <div className="image-box__overlay"></div>
    </section>
  );
};
export default hero2;
