import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Destaque from "../components/destaque"
import RecomChefe from "../components/recomchefe"
import Hero2 from "../components/hero2"
import Menu from "../components/menu"
import Payment from "../components/payment"
import Seo from "../components/seo"
import { motion } from "framer-motion"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" defer={false} />
      <Hero />
      <main>
        <motion.div>
          <Destaque />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <RecomChefe />
        </motion.div>
        <motion.div>
          <Hero2 />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <Menu />
        </motion.div>
        <motion.div>
          <Payment />
        </motion.div>
      </main>
    </Layout>
  )
}

export default IndexPage
