import React from "react"
import SimpleSlider from "./simpleslider"
import { GrRestaurant } from "react-icons/gr"
const recomchefe = () => {
  return (
    <div className="mt-16 w-4/5 h-5/6 mb-16 mx-auto" id="recomendacao">
      <div>
        <h3 className="text-xs font-bold text-red-700  flex justify-items-start items-center lg:text-lg">
          <GrRestaurant className="mr-1 " />
          Sugestão Do Chefe!
        </h3>
      </div>
      <section>
        <SimpleSlider />
      </section>
    </div>
  )
}
export default recomchefe
